import { useState, useRef, useEffect } from 'react';
import '../lineup/style.css'
import aatwBand from '../images/AATW.jpg'
import { motion, AnimatePresence } from 'framer-motion'
import tpi from '../images/TPBB.jpg'
import Modal from '../../components/Modal'
import Modal2 from '../../components/Modal2';

function Lineup() {

    const containerRef = useRef(null)

    const [divVisible, setDivVisible] = useState(false);

    const container = {
        initial: { opacity: 1 },
        visible: {
            opacity: 1,
            transition: {
                delay: 1,
                staggerChildren: .1,
            },
        },
    }

    const item = {
        initial: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
        },
    }

    const options = {
        threshold: 1
    }

    const callbackFunction = (entries) => {
        const [entry] = entries
        if (!divVisible) setDivVisible(entry.isIntersecting)
        // console.log("IT'S VISIBLE YO!")
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)
        if (containerRef.current) observer.observe(containerRef.current)

        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current)
        }

    }, [containerRef, options])


    return (
        <div className='section1'>
            <div className='section-1-container'>
                <h2 id='custom-heading' className='custom-heading-module' ref={containerRef}>
                    <AnimatePresence>
                        <motion.letter variants={container} initial={"initial"} animate={divVisible ? 'visible' : 'initial'}>
                            <span className='word-container'>
                                <motion.ut class='ut-effect-letter' variants={item}>F</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>E</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>A</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>T</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>U</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>R</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>I</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>N</motion.ut>
                                <motion.ut class='ut-effect-letter' variants={item}>G</motion.ut>
                            </span>
                        </motion.letter>
                    </AnimatePresence>
                </h2>
                <img id="aatwpic" src={aatwBand}></img>
                <div className='modal-holder' ><Modal /></div>
            </div>
            <h className='and-sign-lu'>&</h>
            <div className='section2-container-lu'>
                <div className='section-2-lu'>
                    <img id="tppic" src={tpi}></img>
                </div>
            </div>
            <div className='modal-holder2' ><Modal2 /></div>

        </div>
    )
}

export default (Lineup);
import React from 'react';
import { Link } from 'react-router-dom'
import '../landingPage/style.css'
import littleTexas from '../images/lp/littleTexasHeader.svg'

import { BsStar } from 'react-icons/bs'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ScheduleComponent from '../../components/scheduleComponent';

import TicketPrices from '../../components/TicketPrices';
import NextYear from '../../components/NextYear';

import ReactDOM from 'react-dom'
import { SocialIcon } from 'react-social-icons';



const style = { color: '#ac7b32', scale: '460%' }

function LandingPage() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <div className="lp-container">
             
            <div className='lp-content'>
                <div className='red-bar'></div> 
                <div className="header-container">
      <h1 className="title">PRESENTING</h1>
      <div className="image-wrapper">
        <img src={littleTexas} alt="Presentation" className="image" />
      </div>
    </div>
                {/* <div className='under-red'>Presents</div> */}
                {/* <div className='lp-video'> */}
                    {/* <h className='vid-text'>THANK Y'ALL</h>
                    <p className='vid-text-2'>We'll see you next year!</p> */}
                    {/* <div className='btn-container'>
                        <Link to="https://www.eventbrite.com/e/asleep-at-the-wheel-and-bob-wills-texas-playboys-concert-tickets-634809200407"><button className='btn'>BUY TICKETS</button></Link>
                    </div> */}
                    {/* <img src={littleTexas}></img>
                </div> */}
                {/* <div className='red-bar2'>
                    <Link to="/sponsors" className='sponsors-tag'><p id='rbt'>View Our Sponsors</p></Link>
                </div> */}
                <div className='section-2'>
                    <div className='section-2-title'>
                        <div className='star'><BsStar style={style}></BsStar></div>
                        <div className='sec-2-head'>Celebrating Texas</div>
                        <div className='star'><BsStar style={style}></BsStar></div>
                    </div>
                    <div className='section-2-body'>
                        {/* <div className='sec-2-ht-t'>ADMISSION TO THE FESTIVAL<br></br> AND MUSEUM IS</div>
                        <div className='sec-2-ht-t2'>FREE!</div> */}
                        {/* <Link to={{
                            pathname: "/info",
                            hash:"art"
                        }} className='btb'><div className='sec-2-ht-t3'>ARTS FESTIVAL</div></Link> */}
                        {/* <div className='sec-2-ht-t4'></div> */}
                        <div className='section-1-container-info'>
                    <div className='sec-1-info-ht'>Stay tuned for 2025 event details</div>
                </div>
                {/* <div className='sec-1-date-info'>
                Schedule of Events
                </div> */}
                {/* <ScheduleComponent /> */}
                <div className='sec-1-date-info'>
                {/* Ticket Prices */}
                </div>
                <NextYear />
                {/* <TicketPrices /> */}
            </div>
                    </div>
                    
                </div>
                {/* <div className='section-3-lp'>
                    <div className='section-3-title-lp'>
                        <div className='sec-3-ht'>FOOD</div>
                        <div className='sec-3-ht'>EVENTS</div>
                    </div>
                </div> */}
                {/* <div className='section-4-lp'>
                    <div className='section-4-title-lp'>
                        <div className='sec-3-ht'>FUN FOR THE WHOLE FAMILY</div>
                    </div>
                    <div className='image-container-section4'>
                        <img id="jdf-lp" src={JD3}></img>
                        <img id="jdf-lp" src={JD1}></img>
                        <img id="jdf-lp" src={JD8}></img>
                    </div>
                    <div className='image-container-section4'>
                        <img id="jdf-lp" src={JD4}></img>
                        <img id="jdfs-lp" src={JDS}></img>
                        <img id="jdf-lp" src={JD7}></img>
                    </div>
                    <div className='image-container-section4'>
                        <img id="jdf-lp" src={JD12}></img>
                        <img id="jdf-lp" src={JD2}></img>
                        <img id="jdf-lp" src={JD11}></img>
                    </div>
                </div> */}
                {/* <div className='section-5-lp'>
                    <div className='sec-5-ht'>
                        <div className='sec-5-ht-1'>
                            Event Map
                        </div>
                        <div className='map-pv'>
                            Plainview, TX  1900 W 7th
                        </div>
                    </div>
                    <img className="jdf-lp-map" src={Map}></img>
                </div>
                <div className='section-6-lp'>
                    <div className='sec-6-ht'>
                        CLICK HERE FOR MORE INFORMATION
                    </div>
                    <Link to="/info" className='btb'><button className='btn-lp'>INFO</button></Link>
                </div> */}
                {/* <div className='section-7-lp'>
                    <div className='sec-7-h'>
                        VIEW OUR SOCIALS:
                    </div>
                    <div className='social-icons'> */}
                        {/* <Link to='https://twitter.com/WaylandBaptist'><span id='social-icon'><SocialIcon network='twitter' /></span></Link> */}
                        {/* <Link to='https://www.facebook.com/people/Jimmy-Dean-Music-Arts-Festival/100092233669228/'><span id='social-icon'><SocialIcon network='facebook' /></span></Link> */}
                        {/* <Link to='https://www.youtube.com/user/WaylandBaptist'><span id='social-icon'><SocialIcon network='youtube' /></span></Link> */}
                        {/* <Link to='hhttps://www.instagram.com/jimmydeanfest/'><span id='social-icon'><SocialIcon network='instagram' /></span></Link>
                    </div>

                </div> */}
            </div>
    );
}
export default (LandingPage);
// NextYear.jsx
import React from 'react';

const NextYear = () => {
    return (
        <div className="come-back-next-year">
            <h2>Thank you for attending!</h2>
            <p>We hope to see you again next year. Stay tuned for updates!</p>
        </div>
    );
};

export default NextYear;

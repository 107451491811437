import React from 'react';
import '../vendors/style.css'
import { Link } from 'react-router-dom'
import rules from '../images/lp/rules.pdf'
import vendors from '../images/vendors2023.pdf'
import permit from '../images/foodPermit.pdf'
import JDP from '../images/JD/JDP.jpg'

function Vendors() {


    return (
        <div className='section1-vendors'>
            {/* <div className='sec-1-ht-vendors'>BECOME A VENDOR</div> */}
            <div className='section3-info'>
                <div className='sec-3-title'>WHO IS JIMMY DEAN?</div>
                <div className='sec-3-container'>
                    <div className='sec-3-body'>
                        Born Aug. 10, 1928, just 25 miles to the west,
                        Jimmy Dean moved with his family to the Plainview
                        community as a young boy. Although he traveled the
                        world as an entertainer and sausage tycoon, he called
                        Plainview home much of his life. He first sang in Plainview
                        and in 1969 opened his first sausage plant in the city that
                        he loved and that loved him. Later in life, he gave back to
                        the community by donating an organ to Seth Ward Baptist Church,
                        where he attended as a kid, and by supporting Wayland Baptist
                        University. His legacy is found today in Jimmy Dean Hall,
                        a sprawling men’s dormitory on the northeast corner of Wayland’s
                        Plainview campus, as well as the Jimmy Dean Museum, which opened
                        next door in 2016.
                        <br />
                        <br />
                        While the Jimmy Dean Music Festival is not held on
                        his birthday anymore, this is a celebration of this hometown
                        entertainer and entrepreneur’s legacy in country music and
                        business. That’s why every year Wayland takes things up a notch.
                        The celebration is like the man who is remembered — bigger than life.
                        <br />
                        <br />
                        Visit the Jimmy Dean Museum to Learn More.
                    </div>
                    <div className='img-container'><img id='jdp-info' src={JDP}></img></div>
                </div>
                <div className='btn-container-info'>
                    <Link to='https://www.jimmydeanmuseum.com/jimmy-dean-museum' className='info-btn'><button className='buy-tickets-info'>Visit Museum</button></Link>
                </div>


            </div>

        </div>
    )
}
export default (Vendors);

import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Routes, Switch } from 'react-router-dom';
import './App.css';
import { Link } from 'react-router-dom'
import LandingPage from './pages/landingPage'
import NavBar from './pages/NavBar'
import Lineup from './pages/lineup/indexv2.js'
import Info from './pages/info/index.js'
import Vendors from './pages/vendors/index.js'
import Sponsors from './pages/sponsors'
import Tickets from './pages/tickets/index.js'
import ScrollToTop from './scroll'
import NotFound from './components/NotFound.js';


function App() {
  return (
    <Router>
    <NavBar />
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<Vendors />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 page */}
      </Routes>
    </ScrollToTop>
  </Router>
);
}

export default App;

import React, { useEffect, useState } from 'react';
import '../NavBar/style.css'
import JDLogo from '../images/JDFLogo.svg'
import { BsCalendar3 } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'
import { BsMap } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { Squash as Hamburger } from 'hamburger-react'



function NavBar() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='navbar-container'>
            <div className='navbar-content'>
                {/* <div className={`top-line-nav${scrollPosition > 0 ? '-scrolled' : ''}`}> */}
                    {/* <div className='top-nav-text'>
                        <BsCalendar3 /> Sept. 16, 2023 | <FiMail /> jimmydeanfest@gmail.com | <BsMap /> 1900 W 7th
                    </div> */}
                {/* </div> */}
                <div className={`bottom-line-nav${scrollPosition > 0 ? '-scrolled' : ''}`}>
                    <Link to="/tickets" className='nav-link'></Link>
                    <Link to="/tickets" className='nav-link'>TICKETS</Link>
                    <div className='navbar-logo navbar-element'>
                        <Link to="/">
                            <img id={`jdlogo${scrollPosition > 0 ? '-scrolled' : ''}`} src={JDLogo}></img>
                        </Link>
                    </div>
                    <Link to="/about" className='nav-link'>ABOUT</Link>
                    <Link to="/info" className='nav-link'></Link>
                </div>
            </div>

            <div className="topnav">
                <a className="icon" onClick={toggleMenu}>
                    <Hamburger size={30} color='white' toggled={isMenuOpen} />
                </a>
                <img id='jdlogo-scrolled' src={JDLogo}></img>
                <div id="myLinks" style={{ display: isMenuOpen ? 'block' : 'none' }}>
                    <a href="/tickets">TICKETS</a>
                    <Link to="/about" onClick={toggleMenu}>ABOUT</Link>
                    {/* <Link to="/vendors" onClick={toggleMenu}>VENDORS</Link>
                    <Link to="/about" onClick={toggleMenu}>SCHEDULE</Link> */}
                    <Link to="/" onClick={toggleMenu}>HOME</Link>
                </div>
            </div>

        </div>
    );
}
export default (NavBar);




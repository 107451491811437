import React from 'react';
import '../tickets/style.css'

function Tickets() {


    return (
        <div className='section1-tickets'>
            <div className='sec-1-ht-tickets'>TICKETS COMING SOON!</div>
            <div className='sec-1-container-tickets'>
                <div className='sec-1-text-tickets'>We will announce when tickets for the<br /> 2025 Jimmy Dean Music Festival are available.<br /> Thank you!
                </div>
            </div>
        </div>
    )
}
export default (Tickets);